import React from 'react';
import { useRouter } from 'next/router';
import Button from '@nubank/nuds-web/components/Button/Button';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';
import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { trackOpenedWidget } from 'tracking/application/widget';
import { getProspectType } from 'utils/prospectTypes';

import { StickyContainer } from './style';

function DrawerToggleButton() {
  const router = useRouter();
  const {
    openDrawer,
    prospectTypeKey,
    isDrawerOpen,
    hasApplicationFormDrawer,
  } = useApplicationFormDrawerContext();
  const prospectType = getProspectType(prospectTypeKey);

  const handleOpenRegistrationForm = e => {
    trackOpenedWidget({
      prospectType: prospectType.type,
      buttonIndex: 2,
    });
    trackingClickMenu('open_widget');
    sendEvent('CLICK_APPLICATION_BUTTON');
    openDrawer();
    e.target.focus();
  };

  const isUVRevamp = router.pathname === '/ultravioleta/_nd-ueANSxuiosH8Gxqr-w/1';

  return hasApplicationFormDrawer ? (
    <StickyContainer>
      <Button
        id="widget-toggle-button"
        className={prospectType.extraClass ? prospectType.extraClass : ''}
        onClick={handleOpenRegistrationForm}
        extended
        aria-expanded={isDrawerOpen}
        intlKey={isUVRevamp ? 'UV_REVAMP.BUTTON' : prospectType.buttonLabel}
      />
    </StickyContainer>
  ) : null;
}

export default DrawerToggleButton;
